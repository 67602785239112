<template>
<div class="flex flex-col">
    <div
        class="flex flex-col items-center pt-16 pb-24 bg-no-repeat bg-cover lg:flex-row lg:items-start lg:pl-32"
        style="background-image: url('/images/footer.jpg')"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="242" height="71.25" viewBox="0 0 242 71.25" class="text-white fill-current">
            <path d="M0 43.667V36.5h6.333V8.667H0V2h28.667C37.963 2 45.5 11.327 45.5 22.833s-7.537 20.834-16.833 20.834zm17.667-35V36.5h5.416c5.845 0 10.584-6.231 10.584-13.917S28.928 8.667 23.083 8.667z"/>
            <circle cx="5.333" cy="5.333" r="5.333" transform="translate(55.333)"/>
            <path d="M66 36.5V14H51.167v7h4.167v15.5h-4.167v7.167H70V36.5z"/>
            <path d="M94.75 25.437H82.875a5.938 5.938 0 0 1 11.875 0zm1.628 8.422A7.038 7.038 0 0 1 82.9 31.023h21.671a16.224 16.224 0 0 0 .1-1.731A15.958 15.958 0 1 0 88.708 45.25c6.139 0 12.956-2.668 15.625-7.75z"/>
            <path d="M179 25.437h-11.875a5.938 5.938 0 0 1 11.875 0zm1.628 8.422a7.038 7.038 0 0 1-13.48-2.836h21.671a16.222 16.222 0 0 0 .1-1.731 15.959 15.959 0 1 0-15.961 15.958c6.139 0 12.956-2.668 15.625-7.75z"/>
            <path d="M156.708 13.917h-14.791V21h3.215l-3.215 10.333-5.583-17.417h-8.25l-6 17.417L118.688 21h3.4v-7.083h-16.5V21h3.333L117 44.333h8.667l5.583-17.25 5.917 17.25h7.917L153.182 21h3.526z"/>
            <path d="M207.5 32.422a9.932 9.932 0 0 1 10.125-9.953v-9.136a13.008 13.008 0 0 0-11.189 6.167V14h-14.019v7h5.416v15.5h-5.416V44h21v-7.5H207.5z"/>
            <path d="M226.667 38.083c0 3.82 3.656 6.917 8.166 6.917a8.639 8.639 0 0 0 6.772-3.049v-6.284c-1.355.833-4.188 1.416-5.6 0V20.833h5.6v-6.916h-5.522V2.667l-9.833 6.666v4.5h-3.75V21h4.167z"/>
            <path d="M195.826 68.939a2.368 2.368 0 0 0 2.538 2.15 2.683 2.683 0 0 0 2.1-.948v-1.954a1.574 1.574 0 0 1-1.742 0v-8.531H201v-2.15h-2.247v-4.148L195.7 55.43v2.05H194v2.228h1.826z"/>
            <path d="M113.875 64.062a7.188 7.188 0 1 1 7.188 7.188 7.188 7.188 0 0 1-7.188-7.188zm7.188 4.125a4.125 4.125 0 1 0-4.125-4.125 4.125 4.125 0 0 0 4.125 4.125z"/>
            <path d="M211 64.062a7.188 7.188 0 1 1 7.187 7.188A7.187 7.187 0 0 1 211 64.062zm7.187 4.125a4.125 4.125 0 1 0-4.125-4.125 4.124 4.124 0 0 0 4.125 4.125z"/>
            <path d="M100 51.375v2.688h2.648v14.125H100v2.688h7.984v-2.688h-2.648v-6.141h6.611v-2.688h-6.611v-5.3h7.039v2.813h2.688v-5.5z"/>
            <path d="M142.006 68.187V57.25h-4.988v2.687h2.152v6.6a2.477 2.477 0 1 1-4.954 0V57.25h-4.988v2.687h2.147v7.907c0 1.881 2.166 3.406 4.411 3.406a6.522 6.522 0 0 0 4.064-1.512v1.137h4.988v-2.688z"/>
            <path d="M158.952 68.125v-7.906c0-1.881-2.166-3.407-4.411-3.407a6.521 6.521 0 0 0-4.064 1.513v-1.138h-4.988v2.688h2.832v8.312h-2.2v2.625h7.183v-2.687h-2.152v-6.6a2.477 2.477 0 1 1 4.955 0v9.289h4.993v-2.689z"/>
            <path d="M239.853 68.125v-7.906c0-1.881-2.166-3.407-4.411-3.407a6.521 6.521 0 0 0-4.064 1.513v-1.138h-4.988v2.688h2.832v8.312h-2.2v2.625h7.183v-2.687h-2.152v-6.6a2.477 2.477 0 1 1 4.955 0v9.289H242v-2.689z"/>
            <path d="M176.125 68.187l-.031-15.125V51.25h-5.084v2.687h2.542v4.618a7.188 7.188 0 1 0 0 11.015v1.305h5.083v-2.688zm-7.187 0a4.125 4.125 0 1 1 4.124-4.125 4.124 4.124 0 0 1-4.124 4.125z"/>
            <circle cx="2" cy="2" r="2" transform="translate(203.938 50.875)"/>
            <path d="M0 0H4.637V2.688H0z" transform="translate(202.644 57.25)"/>
            <path d="M0 0H9.629V2.688H0z" transform="rotate(90 74.08 133.2)"/>
            <path d="M0 0H7.162V2.688H0z" transform="translate(202.35 68.188)"/>
            <path d="M191.083 68.187v-5.964a5.413 5.413 0 0 0-5.562-5.25 5.513 5.513 0 0 0-5.4 3.974l2.876-.03a2.836 2.836 0 0 1 2.6-1.8 2.78 2.78 0 0 1 2.465 2.422v1.875c-.9-.876-2.184-1-3.632-1-2.7 0-4.895 1.921-4.895 4.291S181.734 71 184.437 71a5.147 5.147 0 0 0 3.632-1.45v1.325H193v-2.688zm-6 .73c-1.564 0-2.833-.989-2.833-2.209s1.269-2.208 2.833-2.208 2.834.989 2.834 2.208-1.269 2.209-2.834 2.209z"/>
        </svg>

        <div class="flex flex-col text-xs text-white lg:flex-row lg:pl-32">
            <div class="h-8 lg:hidden"></div>
            <div class="flex flex-col items-center lg:items-start">
                <span>FAMILIES</span>

                <a class="text-white hover:text-white" href="https://dev.mfood.ca">Mfood</a>
            </div>

            <div class="h-4 lg:hidden"></div>
            <div class="flex flex-col items-center lg:items-start lg:pl-16">
                <span>ORGANIZATIONS</span>

                <a class="text-white hover:text-white" href="https://citydreamcentre.com">City Dream Centre</a>
            </div>

            <div class="h-4 lg:hidden"></div>
            <div class="flex flex-col items-center lg:items-start lg:pl-16">
                <span>WAYS TO GIVE</span>

                <a class="text-white hover:text-white" href="https://dev.mfood.ca">Mfood</a>
            </div>
        </div>
    </div>

    <div class="flex flex-col items-center py-2 bg-black lg:flex-row">
        <div class="flex-1 px-8 text-center text-white text-footer lg:text-left lg:pl-32">
            <span>
                © Diewert Foundation 2021
            </span>

            <router-link class="ml-4 text-white whitespace-nowrap" :to="{ name: 'PrivacyPolicy' }">
                Privacy Policy
            </router-link>

            <router-link class="mx-4 text-white whitespace-nowrap" :to="{ name: 'TermsOfUse' }">
                Terms Of Use
            </router-link>

            <span>
                Charity number: 884461328 RR0001 | BC Society number: S-40668
            </span>
        </div>

        <div class="h-2 lg:hidden"></div>
        <div class="flex items-center flex-0 lg:pr-16">
            <a class="mr-4 text-white" href="#">
                <i class="fab fa-instagram"></i>
            </a>

            <a class="mr-4 text-white" href="#">
                <i class="fab fa-twitter"></i>
            </a>

            <a class="text-white" href="#">
                <i class="fab fa-facebook-f"></i>
            </a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: '',

    props: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

};
</script>
